@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

html,
body {
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    color: white;
}